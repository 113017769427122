.header {
    text-align: center;
    background-color: var(--main);
    color: var(--back);
    padding: 10px;
}
.header_location {
    display:flex;
    justify-content:space-between;
}
.text-xs {
    font-size: 0.7rem;
}

.title {
    display: flex;
    align-items: center;
}

.asagao_size {
    height: 60px;
    margin-left: 10px;
}