.blockly-area {
    width: 100%;
    height: 600px;
}

.blockly-div {
    /* margin: 10px auto 10px 50px; */
    height: 100%;
    /* 70 */
    width: 100%;
    /* 55 */
}
.modal {
    position: relative;
    z-index: 2000; /* 入力フィールドより高い値に設定 */
}


/* XmlSaveDBButton.css */

.xml-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    background-color: var(--background-paper, #fff);
    box-shadow: 24px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.code-box {
    background-color: #f5f5f5;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow-x: auto;
}

.list-item-button {
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    transition: background-color 0.3s ease;
}

.list-item-button:hover {
    background-color: #f0f0f0;
}

.selected {
    background-color: #e0f7fa !important;
    font-weight: bold;
}
.success-message {
    color: green;
}




.success-message {
    color: green;
}
