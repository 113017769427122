.icon {
    display: flex;
    align-items: center;
}
 
.icon_location {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    margin-right: 20px;
    position: relative; 
}

.icon_text {
    font-weight: bold;
    text-align: center;
}
.icon_location:hover {
    border-bottom: 2px solid;
}

.icon_location:hover .dropdown-menu{
    display: block; /* hover 時に表示 */
}
.dropdown-menu {
    position: absolute;
    top: 100%; /* 親要素の高さの直下に配置 */
    left: 50%; /* 親要素の中央揃え */
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%); /* 水平位置を中央に調整 */
    padding: 10px;
    border-radius: 4px;
    z-index: 100;
    display: none; /* 初期状態では非表示 */
    white-space: nowrap; /* テキストを折り返さない */
}

.dropdown-item {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.dropdown-item:hover {
    background-color: #f0f0f0;
}

.modal {
    position: relative;
}


.login_modal {
    position: absolute;
    top: 30%;
    left: 70%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
    padding: 16px;
  }  
