.modal {
    position: relative;
    z-index: 2000; /* 入力フィールドより高い値に設定 */
}

.modal-box {
    position: absolute;
    width: 80%;
    height: 80%;
    padding: 20px;
    background: white;
    border: solid 1px lightgrey;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    overflow-y: auto;
}
.search-bar {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.search-input {
    width: 200px;
    margin-left: 10px;
}
.modal-header {
    margin-bottom: 10px;
}

.plfiles-index {
    height: 85%;
}

.plfiles-index-list {
    height: 100%;
    overflow-y: auto;
    border-right: solid 1px lightgrey;
}

.plfiles-index-list-item {
    border-bottom: solid 1px lightgrey;
}

.plfile-detail {
    height: 60vh;
    overflow-y: scroll;
    padding: 0px 20px;
    margin-top: 10px;
    border: solid 1px lightgrey;
}



