body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: break-all;
    margin: 0;
    padding: 0;
    min-width: 768px;
}

:root {
    --load: #9ec34b;
    --save: #3333FF;
    --run: #FF9999;
    --add: palevioletred;
    --back: #f8f5f1;
    --subsub: #f8a488;
    --sub: #5aa897;
    --main: #45526c;
}

h1,
h2,
h3,
p {
    margin: 0;
    padding: 0;
}

.color-load {
    color: var(--load) !important;
}

.color-save {
    color: var(--save) !important;
}

.color-run {
    color: var(--run) !important;
}

.color-add {
    color: var(--add) !important;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}