.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.loadingInner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 80%;
    text-align: center;
    height: 30%;
}

.loadgingText {
    color: white;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}

.loadingClose {
    text-align: right;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
}

/*エラーメッセージ*/
.popup-content h3 {
    margin-top: 0;
}

.popup-content p {
    margin-bottom: 10px;
}